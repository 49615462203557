import React from 'react';
import {Result, Modal, Table} from 'antd';

const appConfig = require('../data.json');

export default function WelcomePage() {
  const showContactModal = () => {
    const contactTable = (
      <Table
        dataSource={[
          {
            type: '網站',
            content: 'https://www.eculture.tech/',
            render: (
              <a
                href="https://www.eculture.tech/"
                target="_blank"
                style={{color: 'black', textDecoration: 'none'}}
                rel="noreferrer">
                https://www.eculture.tech/
              </a>
            ),
          },
          {
            type: '電話',
            content: '02-7729-5130 #301',
            render: (
              <a
                href="tel:+88677295130"
                style={{color: 'black', textDecoration: 'none'}}>
                02-7729-5130 #301
              </a>
            ),
          },
          {
            type: '電子郵件',
            content: 'contact@eculture.tech',
            render: (
              <a
                href="mailto:contact@eculture.tech"
                style={{color: 'black', textDecoration: 'none'}}>
                contact@eculture.tech
              </a>
            ),
          },
          {
            type: 'Line',
            content: 'HiPrint 印刷電商新零售',
            render: (
              <a
                href="https://lin.ee/cHZvSyI"
                target="_blank"
                style={{color: 'black', textDecoration: 'none'}}
                rel="noreferrer">
                HiPrint 印刷電商新零售
              </a>
            ),
          },
        ]}
        columns={[
          {
            title: '類型',
            dataIndex: 'type',
            key: 'type',
          },
          {
            title: '連結',
            dataIndex: 'content',
            key: 'content',
            render: (_, o) => o.render,
          },
        ]}
        pagination={false}
        width={'100%'}
      />
    );

    Modal.info({
      title: '聯繫奕果取得更多資訊',
      content: contactTable,
      onOk() {},
    });
  };

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <h2 style={{color: appConfig.colors.main}}>HiPrint 後台系統</h2>
      <h3 style={{marginBottom: 30}}>請點選左側導覽列進入管理頁面</h3>

      <img
        style={{width: '75%', borderRadius: 20}}
        src="/eculture-buy.webp"
        onClick={showContactModal}
      />
    </div>
  );
}
